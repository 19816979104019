import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAmNT-txoDq69VYV_5-6ZYMT3-JyOmYBO4",
  authDomain: "empadigital-18b21.firebaseapp.com",
  projectId: "empadigital-18b21",
  storageBucket: "empadigital-18b21.appspot.com",
  messagingSenderId: "905394253300",
  appId: "1:905394253300:web:4df7430dd8445ea3079da1",
  measurementId: "G-3M9GQY0JCF",
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const messaging = getMessaging(firebaseApp);

const eventLogger = (eventName: string, params: any = {}) => {
  logEvent(analytics, eventName, params);
};

const loginAnalytics = (username: string) => {
  setUserId(analytics, username);
};

const setAnalyticProperties = (
  roles: string[],
  office: "ANKARA" | "ISTANBUL",
  department: string,
  lines: string[]
) => {
  setUserProperties(analytics, { roles, office, department, lines });
};

export {
  analytics,
  messaging,
  eventLogger,
  loginAnalytics,
  setAnalyticProperties,
};
