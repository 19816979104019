const FILTER = "quote_filter" as string;
import type { IQuotesFilterDatas } from "@/interfaces/quotes.interface";

/**
 * @description get quote filters form sessionStorage
 */
export const getQuoteFilter = (): IQuotesFilterDatas | null => {
  const filter = window.sessionStorage.getItem(FILTER);
  return filter ? JSON.parse(filter) : null;
};

/**
 * @description save quote filters sessionStorage
 * @param filter: IQuotesFilterDatas
 */
export const saveQuoteFilter = (filter: IQuotesFilterDatas | null): void => {
  window.sessionStorage.setItem(FILTER, JSON.stringify(filter));
};

/**
 * @description remove quote filters form sessionStorage
 */
export const destroyQuoteFilter = (): void => {
  window.sessionStorage.removeItem(FILTER);
};

export default { getQuoteFilter, saveQuoteFilter, destroyQuoteFilter };
