const FILTER = "special_prices_filter" as string;
import type { ISpecialPriceFilterDatas } from "@/interfaces/special-prices.interface";

/**
 * @description get special prices filters form sessionStorage
 */
export const getSpecialPricesFilter = (): ISpecialPriceFilterDatas | null => {
  const filter = window.sessionStorage.getItem(FILTER);
  return filter ? JSON.parse(filter) : null;
};

/**
 * @description save special prices filters sessionStorage
 * @param filter: ISpecialPriceFilterDatas
 */
export const saveSpecialPricesFilter = (filter: ISpecialPriceFilterDatas | null): void => {
  window.sessionStorage.setItem(FILTER, JSON.stringify(filter));
};

/**
 * @description remove special prices filters form sessionStorage
 */
export const destroySpecialPricesFilter = (): void => {
  window.sessionStorage.removeItem(FILTER);
};

export default { getSpecialPricesFilter, saveSpecialPricesFilter, destroySpecialPricesFilter };
