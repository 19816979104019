import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { ThemeModeComponent } from "@/assets/ts/layout";

export const THEME_MODE_LS_KEY = "kt_theme_mode_value";
export const THEME_MENU_MODE_LS_KEY = "kt_theme_mode_menu";

export const useThemeStore = defineStore("theme", () => {
  const mode = ref<"light" | "dark" | "system">(
    localStorage.getItem(THEME_MODE_LS_KEY) as "light" | "dark" | "system"
  );

  function setThemeMode(payload: "light" | "dark" | "system") {
    let currentMode = payload;
    localStorage.setItem(THEME_MODE_LS_KEY, currentMode);
    localStorage.setItem(THEME_MENU_MODE_LS_KEY, currentMode);
    mode.value = currentMode;

    if (currentMode === "system") {
      currentMode = ThemeModeComponent.getSystemMode();
    }

    document.documentElement.setAttribute("data-bs-theme", currentMode);
    ThemeModeComponent.init();
  }

  function getTheme(){
    if(mode.value === 'system') return ThemeModeComponent.getSystemMode();
    else return mode.value
  }

  function isDark() {
    if (mode.value === "dark") {
      return true;
    } else if (mode.value === "system") {
      return ThemeModeComponent.getSystemMode() === "dark";
    } else {
      return false;
    }
  }

  function isLight() {
    if (mode.value === "light") {
      return true;
    } else if (mode.value === "system") {
      return ThemeModeComponent.getSystemMode() === "light";
    } else {
      return false;
    }
  }

  return {
    mode,
    setThemeMode,
    isDark,
    isLight,
    getTheme
  };
});
