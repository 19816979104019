const FILTER = "activity_filter" as string;
import type { IActivityFilterDatas } from "@/interfaces/activities.interface";

/**
 * @description get activity filters form sessionStorage
 */
export const getActivityFilter = (): IActivityFilterDatas | null => {
  const filter = window.sessionStorage.getItem(FILTER);
  return filter ? JSON.parse(filter) : null;
};

/**
 * @description save activity filters sessionStorage
 * @param filter: IActivityFilterDatas
 */
export const saveActivityFilter = (filter: IActivityFilterDatas | null): void => {
  window.sessionStorage.setItem(FILTER, JSON.stringify(filter));
};

/**
 * @description remove activity filters form sessionStorage
 */
export const destroyActivityFilter = (): void => {
  window.sessionStorage.removeItem(FILTER);
};

export default { getActivityFilter, saveActivityFilter, destroyActivityFilter };
