const USER_INFO = "user_info" as string;

/**
 * @description get user form localStorage
 */
export const getUserInfo = (): string | null => {
  const user = window.localStorage.getItem(USER_INFO);
  return user ? JSON.parse(user) : null;
};

/**
 * @description save user into localStorage
 * @param user: string
 */
export const saveUserInfo = (user: string): void => {
  window.localStorage.setItem(USER_INFO, JSON.stringify(user));
};

/**
 * @description remove user form localStorage
 */
export const destroyUserInfo = (): void => {
  window.localStorage.removeItem(USER_INFO);
};

export default { getUserInfo, saveUserInfo, destroyUserInfo };
