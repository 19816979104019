import { ref, computed } from "vue";
import { defineStore } from "pinia";
import type { UseWebSocketReturn } from "@vueuse/core";
import { useWebSocket } from "@vueuse/core";
import JwtService, { getToken } from "@/core/services/JwtService";
import { useAuthStore } from "@/stores/auth";
import ApiService from "@/core/services/ApiService";

export interface WS {
  websocket: UseWebSocketReturn<any>;
}

export const useWebsocketStore = defineStore("websocket", () => {
  const hasNewMessage = ref(false);

  // create getWebsocket getters using computed
  const getWebsocket = computed(() => {
    return websocket;
  });

  // create getHasNewMessage getters using computed
  const getHasNewMessage = computed(() => {
    return hasNewMessage.value;
  });

  async function refreshToken() {
    const authStore = useAuthStore();
    await authStore.verifyAuth({
      refresh: JwtService.getRefreshToken(),
    });
  }

  //define websocket while using useWebSocket
  const websocket = useWebSocket(
    import.meta.env.VITE_APP_WS_URL + `?token=${getToken()}`,
    {
      immediate: false,
      autoReconnect: {
        delay: 1000,
        onFailed: refreshToken,
      },
    }
  );

  function setNewMessageNotification(value: boolean) {
    hasNewMessage.value = value;
  }

  async function chatMessageList(params) {
    try {
      const response = await ApiService.get(
        "/api/utility/chat-message/" + params
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function sendPageView(page: string, resource: null | string) {
    websocket.send(JSON.stringify({ action: "page-view", page: page , resource: resource  }));
  }

  return {
    hasNewMessage,
    websocket,
    getWebsocket,
    getHasNewMessage,
    sendPageView,
    refreshToken,
    chatMessageList,
    setNewMessageNotification,
  };
});
