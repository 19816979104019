import { ref } from "vue";
import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";

export interface Notifications {
  notification: Notification;
}

export interface NotificationResponse {
  id: number;
  content: {
    title: string;
    description: string;
    link: string;
  }
  is_read: boolean;
  created_at: string;
}

export interface NotificationDatas {
  notifications: NotificationResponse[];
  next?: string;
  previous?: string;
}

export const useNotificationsStore = defineStore("notifications", () => {
  const notification = ref<Notification>({} as Notification);
  const notifications = ref<NotificationDatas>({} as NotificationDatas);

  function notificationList() {
    return notifications.value;
  }

  function setNotificationList(info) {
    notifications.value = info;
  }

  function refreshNotificationList(info) {
    setNotificationList({
      notifications: info.notifications,
      next: info.next,
      previous: info.previous,
    });
  }

  async function getNotificationList(params: string) {
    try {
      const response = await ApiService.get(`/api/notification/${params}`);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getNotificationListByCategory() {
    try {
      const response = await ApiService.get(`/api/notification/category/`);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function notificationItemRead(id: unknown) {
    try {
      const response = await ApiService.post(
        `/api/notification/${id}/read/`,
        {}
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function notificationAllItemRead() {
    try {
      const response = await ApiService.post(`/api/notification/read-all/`, {});
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    notificationList,
    setNotificationList,
    refreshNotificationList,
    getNotificationList,
    notificationItemRead,
    notificationAllItemRead,
    getNotificationListByCategory,
  };
});
