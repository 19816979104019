const FILTER = "project_filter" as string;
import type { IProjectFilterDatas } from "@/interfaces/project.interface";

/**
 * @description get project filters form sessionStorage
 */
export const getProjectFilter = (): IProjectFilterDatas | null => {
  const filter = window.sessionStorage.getItem(FILTER);
  return filter ? JSON.parse(filter) : null;
};

/**
 * @description save project filters sessionStorage
 * @param filter: IProjectFilterDatas
 */
export const saveProjectFilter = (filter: IProjectFilterDatas | null): void => {
  window.sessionStorage.setItem(FILTER, JSON.stringify(filter));
};

/**
 * @description remove project filters form sessionStorage
 */
export const destroyProjectFilter = (): void => {
  window.sessionStorage.removeItem(FILTER);
};

export default { getProjectFilter, saveProjectFilter, destroyProjectFilter };
