import type { App } from "vue";
import type { AxiosResponse } from "axios";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import type { AxiosAuthRefreshRequestConfig } from "axios-auth-refresh";
import { useAuthStore } from "@/stores/auth";
import router from "../../router";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    const refreshAuthLogic = (failedRequest) =>
      ApiService.post(
        import.meta.env.VITE_APP_BASE_URL + "/api/user/token/refresh/",
        {
          refresh: JwtService.getRefreshToken(),
        },
        { skipAuthRefresh: true }
      )
        .then((tokenRefreshResponse) => {
          JwtService.refreshToken(tokenRefreshResponse.data.refresh);
          JwtService.saveToken(tokenRefreshResponse.data.access);
          failedRequest.response.config.headers["Authorization"] =
            "Bearer " + tokenRefreshResponse.data.access;
          ApiService.setHeader();
          return Promise.resolve();
        })
        .catch((error) => {
          useAuthStore().logout();
          router.push("/sign-in");
          return Promise.reject();
        });

    createAuthRefreshInterceptor(axios, refreshAuthLogic, {
      statusCodes: [401, 403],
    });

    // This is running right before each request is sent to the server
    axios.interceptors.request.use((request) => {
      // Makes sure each requests is using the latest accessToken available
      if (JwtService.getToken()) {
        request.headers["Authorization"] = `Bearer ${JwtService.getToken()}`;
      }

      return request;
    });

    axios.interceptors.response.use(function (res) {
        return res;
      },
      (err) => {
        if (err.response.status === 503) {
          return new Promise((resolve, reject) => {
            router.push("/maintenance");
          });
        }
        return Promise.reject(err);
      });


    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL =
      import.meta.env.VITE_APP_BASE_URL;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
    /**
     * Change language
     */
    let currentLang = window.localStorage.getItem("lang")
      ? window.localStorage.getItem("lang")
      : "tr";

    ApiService.vueInstance.axios.defaults.headers.common["accept-language"] =
      currentLang == "tr" ? "tr-TR" : "en-US;q=0.8,en;q=0.7";
  }

  /**
   * @description axios axios
   */
  public static axios() {
    return ApiService.vueInstance.axios;
  }

  /**
   * @description axios interceptors
   */
  public static interceptors() {
    return ApiService.vueInstance.axios.interceptors;
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(resource: string, params: any): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: any,
    axiosConfig?: AxiosAuthRefreshRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(
      `${resource}`,
      params,
      axiosConfig
    );
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: any,
    axiosConfig?: AxiosAuthRefreshRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(
      `${resource}/${slug}`,
      params,
      axiosConfig
    );
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: any,
    axiosConfig?: AxiosAuthRefreshRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params, axiosConfig);
  }

  /**
   * @description Send the PATCH HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static patch(resource: string, params: any): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.patch(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string, params?: any): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource, { data: params });
  }
}

export default ApiService;
