const FIREBASE_TOKEN_KEY = "firebase_token" as string;

/**
 * @description get firebase token form localStorage
 */
export const getFirebaseToken = (): string | null => {
  return window.localStorage.getItem(FIREBASE_TOKEN_KEY);
};

/**
 * @description save firebase token into localStorage
 * @param token: string
 */
export const saveFirebaseToken = (token: string): void => {
  window.localStorage.setItem(FIREBASE_TOKEN_KEY, token);
};

/**
 * @description remove firebase token form localStorage
 */
export const destroyFirebaseToken = (): void => {
  window.localStorage.removeItem(FIREBASE_TOKEN_KEY);
};

export default {
  getFirebaseToken,
  saveFirebaseToken,
  destroyFirebaseToken,
};
