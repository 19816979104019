const FILTER = "pof_filter" as string;
import type { IPofFilterDatas } from "@/interfaces/pof.interface";

/**
 * @description get Purchase Order Form filters form sessionStorage
 */
export const getPurchaseOrderFormFilter = (): IPofFilterDatas | null => {
  const filter = window.sessionStorage.getItem(FILTER);
  return filter ? JSON.parse(filter) : null;
};

/**
 * @description save Purchase Order Form filters sessionStorage
 * @param filter: IPofFilterDatas
 */
export const savePurchaseOrderFormFilter = (
  filter: IPofFilterDatas | null
): void => {
  window.sessionStorage.setItem(FILTER, JSON.stringify(filter));
};

/**
 * @description remove Purchase Order Form filters form sessionStorage
 */
export const destroyPurchaseOrderFormFilter = (): void => {
  window.sessionStorage.removeItem(FILTER);
};

export default {
  getPurchaseOrderFormFilter,
  savePurchaseOrderFormFilter,
  destroyPurchaseOrderFormFilter,
};
